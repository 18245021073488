<template>
  <v-row>
    <v-col cols="12" xl="6" lg="8">
      <util-user-form type="profile" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
